<template>
    <v-container grid-list-xs class="mx-auto pa-8">
        <v-card
            class="mx-auto pa-4"
            max-width="600"
            flat
        >
            <v-card-text>
            <p class="display-1 text--primary">
                <v-icon>mdi-alert</v-icon>
                RISK REPORTING FORM
            </p>
            <p>Tell us what happen. Complete the details below.</p>
            </v-card-text>
            <div class="pa-4">
                <v-select
                    :items="Departments"
                    v-model="Department"
                    item-text="departmentName"
                    label="Department"
                    outlined
                    class="mb-1"
                    multiple
                ></v-select>
                <v-text-field
                    name="name"
                    label="Affected Branch/SA/Partner/Client"
                    id="id"
                    v-model="AffectedBranch"
                    outlined
                    class="mb-1"
                ></v-text-field>
                <v-select
                    :items="returnRiskSelection"
                    v-model="RiskTranspired"
                    label="Risk Transpired"
                    outlined
                    class="mb-1"
                ></v-select>
                <v-text-field
                    name="name"
                    v-model="ImpactAmount"
                    label="Impact/Amount (if applicable)"
                    id="id"
                    outlined
                    class="mb-1"
                ></v-text-field>
                <v-row>
                    <v-col>
                        <v-menu
                            ref="menu1"
                            v-model="menu1"
                            :close-on-content-click="false"
                            :return-value.sync="DateOccurrence"
                            transition="scale-transition"
                            offset-y
                            min-width="290px"
                        >
                            <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                                v-model="DateOccurrence"
                                label="Date of Occurence"
                                prepend-icon="mdi-calendar"
                                readonly
                                v-bind="attrs"
                                v-on="on"
                                class="mb-1"
                                outlined
                            ></v-text-field>
                            </template>
                            <v-date-picker
                            v-model="DateOccurrence"
                            no-title
                            scrollable
                            >
                            <v-spacer></v-spacer>
                            <v-btn
                                text
                                color="primary"
                                @click="menu1 = false"
                            >
                                Cancel
                            </v-btn>
                            <v-btn
                                text
                                color="primary"
                                @click="$refs.menu1.save(DateOccurrence)"
                            >
                                OK
                            </v-btn>
                            </v-date-picker>
                        </v-menu>
                        <v-text-field
                            name="name"
                            v-model="ReasonDelay"
                            label="Reason for Delay"
                            id="id"
                            outlined
                            class="mb-1"
                        ></v-text-field>
                    </v-col>

                    <v-col>
                       <v-menu
                            ref="menu2"
                            v-model="menu2"
                            :close-on-content-click="false"
                            :return-value.sync="DateDetection"
                            transition="scale-transition"
                            offset-y
                            min-width="290px"
                        >
                            <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                                v-model="DateDetection"
                                label="Date of Detection"
                                prepend-icon="mdi-calendar"
                                readonly
                                v-bind="attrs"
                                v-on="on"
                                class="mb-1"
                                outlined
                            ></v-text-field>
                            </template>
                            <v-date-picker
                            v-model="DateDetection"
                            no-title
                            scrollable
                            >
                            <v-spacer></v-spacer>
                            <v-btn
                                text
                                color="primary"
                                @click="menu2 = false"
                            >
                                Cancel
                            </v-btn>
                            <v-btn
                                text
                                color="primary"
                                @click="$refs.menu2.save(DateDetection)"
                            >
                                OK
                            </v-btn>
                            </v-date-picker>
                        </v-menu>
                       <v-menu
                            ref="menu3"
                            v-model="menu3"
                            :close-on-content-click="false"
                            :return-value.sync="DateReported"
                            transition="scale-transition"
                            offset-y
                            min-width="290px"
                        >
                            <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                                v-model="DateReported"
                                label="Date Reported"
                                prepend-icon="mdi-calendar"
                                readonly
                                v-bind="attrs"
                                v-on="on"
                                class="mb-1"
                                outlined
                            ></v-text-field>
                            </template>
                            <v-date-picker
                            v-model="DateReported"
                            no-title
                            scrollable
                            >
                            <v-spacer></v-spacer>
                            <v-btn
                                text
                                color="primary"
                                @click="menu3 = false"
                            >
                                Cancel
                            </v-btn>
                            <v-btn
                                text
                                color="primary"
                                @click="$refs.menu3.save(DateReported)"
                            >
                                OK
                            </v-btn>
                            </v-date-picker>
                        </v-menu>                                             
                    </v-col>
                </v-row>
                <v-radio-group
                v-model="WithInsurance"
                row
                label="With Insurance?"
                class="mb-1"
                >
                <v-radio
                    label="Yes"
                    :value="true"
                ></v-radio>
                <v-radio
                    label="No"
                    :value="false"
                ></v-radio>
                </v-radio-group>
                <div class="mb-1">
                    <span>If Fraud/Criminality, commited by:</span>
                    <v-row class="pa-4">
                        <v-checkbox
                        v-model="CommitedBy"
                        label="Internal/Staff"
                        value="Internal/Staff"
                        class="col-6 pa-0"
                        ></v-checkbox>
                        <v-checkbox
                        v-model="CommitedBy"
                        label="Clients"
                        value="Clients"
                        class="col-6 pa-0"
                        ></v-checkbox>
                        <v-checkbox
                        v-model="CommitedBy"
                        label="Outsiders"
                        value="Outsiders"
                        class="col-6 pa-0"
                        ></v-checkbox>
                        <v-checkbox
                        v-model="CommitedBy"
                        label="Partners"
                        value="Partners"
                        class="col-6 pa-0"
                        ></v-checkbox>
                    </v-row>
                </div>
                <v-textarea
                    outlined
                    v-model="IncidentDetails"
                    name="input-7-4"
                    label="Incident Details"
                    value="The Woodman set to work at once, and so sharp was his axe that the tree was soon chopped nearly through."
                ></v-textarea>                

            </div>
            <v-card-actions class="row pa-6">
            <v-btn
                color="grey"
                text
                class="col"
            >
                
                cancel
            </v-btn>
            <v-btn
                color="primary"
                dark
                class="col"
                @click="submitReport"
            >
                
                submit report
            </v-btn>
            </v-card-actions>
        </v-card>        
    </v-container>
</template>
<script>
export default {
    data: () => ({
        menu1: false,
        menu2: false,
        menu3: false,
        Department: '',
        AffectedBranch: '',
        RiskTranspired: null,
        ImpactAmount: '',
        DateOccurrence: '',
        ReasonDelay: '',
        DateDetection: '',
        DateReported: '',
        WithInsurance: false,
        CommitedBy: [],
        IncidentDetails: ''
    }),
    firestore(){
        return {
            Departments: this.$db.collection('Departments').where('departmentName','!=','RISK MANAGEMENT TEAM'),
            Risk: this.$db.collection('Risk')
        }
    },    
    computed:{
        returnRiskSelection(){
            return this.Risk.map(a=>{
                return {
                    text: a.RiskName,
                    value: a
                }
            })
        }
    },
    methods:{
        async submitReport(){
            let risk = {...this.RiskTranspired}
            delete risk['.key']
            risk.riskKey = this.RiskTranspired['.key']
            let report = {
                Department: this.Department,
                AffectedBranch: this.AffectedBranch,
                ImpactAmount: this.ImpactAmount,
                DateOccurrence: this.DateOccurrence,
                ReasonDelay: this.ReasonDelay,
                DateDetection: this.DateDetection,
                DateReported: this.DateReported,
                WithInsurance: this.WithInsurance,
                CommitedBy: this.CommitedBy,
                IncidentDetails: this.IncidentDetails,
                ...risk
            }


            console.log(report,'submit');
            if(report.Department.length == 0 || report.IncidentDetails == '' || report.DateReported == ''  || report.DateDetection == '' || report.ReasonDelay == '' || report.DateOccurrence == '' || report.AffectedBranch == '' ||  report.RiskTranspired == '' ||  report.ImpactAmount == '' ||  report.CommitedBy.length == 0){
                this.$dialog.error({
                    text: `Please Complete all the needed fields. Thank you`,
                    title: `Incomplete Report`
                })
                return
            }

            const confirm = await this.$dialog.confirm({
                text: `Do you want to send this report? `,
                title: `Confirm Sending of Report`
            })
            if(!confirm){
                return
            }   

            
            let user = this.$store.getters['useraccount/isAuthenticated']
            
            if(confirm){
            let newReport = report
            newReport.userID = user.email
            newReport.timestamp = this.$firebase.firestore.FieldValue.serverTimestamp()
            this.$db.collection('ReportedRisk').add(newReport)
            .then(()=>{
                this.$dialog.notify.success(`Success Sending Risk Report`, {
                    position: 'bottom-right',
                    timeout: 3000
                }) 
                    this.AffectedBranch = ''
                    this.Department = ''
                    this.RiskTranspired = null
                    this.ImpactAmount = ''
                    this.DateOccurrence = ''
                    this.ReasonDelay = ''
                    this.DateDetection = ''
                    this.DateReported = ''
                    this.WithInsurance = false
                    this.CommitedBy = []
                    this.IncidentDetails = ''
                    this.$router.go(-1)
            }).catch(err=>{
                this.$dialog.notify.success(`Error : ${err}`, {
                    position: 'bottom-right',
                    timeout: 3000
                })              
            })
            }
            

        }
    }
}
</script>